<template>
  <Layout>
    <div class="base-width">
      <div class="main-pay text-center s16 c-card">
        <!-- 订单详情按钮 -->
        <div class="flex-right">
          <BaseButton style-type="light" icon="right" tag="router-link" :to="`/order-detail?order_id=${order_id}`">查看订单详情
          </BaseButton>
        </div>
        <div class="color-4 t1 lh-30">
          采购支付剩余时间：{{ dateObj.hours }}时{{ dateObj.minutes }}分{{ dateObj.seconds }}秒
        </div>
        <div class="font-medium t2 price flex-center">
          <span class="s32 color-2">¥{{ baseObj.order_amount }}</span>
          <span class="s26 color-red" v-if="baseObj.pay_type == 2">（预付款）</span>
        </div>
        <div class="color-red t3 lh-30" v-if="baseObj.pay_type == 2">
          为保证三流合一，建议选择“线下支付”采用企业对公支付
        </div>
        <div class="line"></div>
        <div class="color-4 t4 lh-30">
          请在
          {{ dateObj.hours }}时{{ dateObj.minutes }}分{{ dateObj.seconds }}秒
          内完成，否则订单将会自动取消
        </div>
        <!-- 支付方式 -->
        <OrderPayWay v-model="payWay" :user_money="baseObj.user_money"></OrderPayWay>
        <!-- 按钮 -->
        <div class="s16 flex-center btns color-f">
          <div class="btn btn-1" @click="handlePay">立即支付</div>
          <div class="btn btn-2 bg-9" @click="cancelClick">取消</div>
        </div>
      </div>
    </div>
    <BaseDialog :visible.sync='visible' title="信息提示" :cancel='false' @confirm='visible = false'>
      <div class="s26 color-2 font-medium text-center dialog-text">
        请尽快进行完成支付<br>
        该订单将保留30分钟
      </div>
    </BaseDialog>
  </Layout>
</template>

<script>
import { getCountDownObj } from '@/utils';
import { OrderAmountApi, OrderPayApi } from "@/request/api/order";
export default {
  created() {
    this.order_id = this.$route.query.order_id;
    this.merge_order_sn = this.$route.query.merge_order_sn;
    OrderAmountApi({ order_id: this.order_id, merge_order_sn: this.merge_order_sn }).then(({ status, data }) => {
      if (status == 200) {
        this.baseObj = data;
      }
    })
  },
  data() {
    return {
      order_id: "",
      merge_order_sn: "",
      baseObj: {},
      payWay: 1,
      visible: true,
      payTime: '',
      timer: '',
      countTime: "",
      dateObj: {}
    };
  },
  mounted() {
    this.payTime = Date.now() + (30 * 60 * 1000)
    this.countDown()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    cancelClick(){
      this.$router.push(`/order?tab=3&status=1`);
    },
    // 支付
    handlePay() {
      // 线下
      if (this.payWay == 3) {
        this.$router.push(`/pay-offline?order_id=${this.order_id}&merge_order_sn=${this.merge_order_sn}`);
        return;
      }
      OrderPayApi({
        order_id: this.order_id,
        merge_order_sn: this.merge_order_sn,
        pay_type: this.payWay
      }).then(({ status, data }) => {
        if (status == 200) {
          // 微信
          if (this.payWay == 1) {
            this.$router.push(`/pay-code?order_id=${this.order_id}&merge_order_sn=${this.merge_order_sn}&qrcode_url=${data.param.qrcode_url}&order_sn=${data.order_sn}`);
            return;
          }
          // 支付宝
          if (this.payWay == 2) {
            const div = document.createElement('div');
            div.innerHTML = data.param;
            document.body.appendChild(div);
            document.forms['alipaysubmit'].submit();
          }
          // 余额
          if (this.payWay == 4) {
            this.$router.push(`/pay-success?order_sn=${data.order_sn}&order_id=${this.order_id}`);
            return;
          }
        }
      })
    },
    countDown() {
      this.timer = setInterval(() => {
        if (this.payTime > Date.now()) {
          this.dateObj = getCountDownObj(this.payTime)
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
  },
};
</script>

<style lang="scss" scoped>
.main-pay {
  padding: .3rem;
  padding-bottom: .8rem;
  margin-top: .37rem;
  margin-bottom: .6rem;

  .t1 {
    margin-top: .02rem;
  }

  .price {
    margin-top: .12rem;
  }

  .t3 {
    margin-top: .14rem;
  }

  .lh-30 {
    line-height: calc(30 / 16 * 1em);
  }

  .line {
    height: 1px;
    background-color: #e6f2f8;
    margin: .23rem 0 .2rem;
  }

  .btns {
    margin-top: .36rem;

    .btn {
      width: 1.3rem;
      line-height: 0.5rem;
      border-radius: 0.04rem;
      margin: 0 .1rem;
      text-align: center;
      cursor: pointer;
    }

    .btn-1 {
      background: #e01624;
    }
  }
}

.pay-way {
  margin-top: .22rem;
}

.dialog-text {
  margin: 0.38rem 0 .23rem;
  line-height: calc(42 / 26 * 1em);
}
</style>